export default {
   MAX_ATTACHMENT_SIZE: 5000000,

   s3: {
     REGION: "us-east-1",
     BUCKET: "notes-app-2-api-prod-attachmentsbucket-1n5mujcqtigit"
   },
   apiGateway: {
     REGION: "us-east-1",
     URL: "https://mcgjpu0a3c.execute-api.us-east-1.amazonaws.com/prod"
   },
   cognito: {
     REGION: "us-east-1",
     USER_POOL_ID: "us-east-1_T6GuAG6qt",
     APP_CLIENT_ID: "1n88t8jic9qfosb6lirvh4b0vj",
     IDENTITY_POOL_ID: "us-east-1:81f6d354-0e51-4114-b374-18316ab091bf"
   }
 };